import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en.json';
import translationAR from './locales/ar.json';
import translationDE from './locales/de.json';
import translationES from './locales/es.json';
import translationFR from './locales/fr.json';
import translationHE from './locales/he.json';
import translationKO from './locales/ko.json';
import translationPT from './locales/pt.json';
import translationCN from './locales/cn.json';
import talentKraft from './locales/talentKraft.json';

export const resources = {
  en: { translation: translationEN },
  ar: { translation: translationAR },
  de: { translation: translationDE },
  es: { translation: translationES },
  fr: { translation: translationFR },
  he: { translation: translationHE },
  ko: { translation: translationKO },
  pt: { translation: translationPT },
  cn: { translation: translationCN },
  talentKraft: { translation: talentKraft },
} as const;

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    lng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
  });

export default i18n;
