import React, { lazy, Suspense } from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { Routes, Route } from 'react-router-dom';
import './i18n/config';

const CompanyWelcomePageWrapper = lazy(() => import('./views/CompanyWelcomePage/Wrapper'));
const NotFound = lazy(() => import('./views/NotFound'));
const Main = lazy(() => import('./views/Main'));

const App = () => (
  <IntercomProvider appId="pzs0slby">
    <div className="app">
      <Suspense fallback={React.Fragment}>
        <Routes>
          <Route path="/:jobId/:companySlug/:jobSlug" element={<Main />} />
          <Route path="/:companySlug/:jobSlug" element={<Main />} />
          <Route path="/:candidateId" element={<Main isPrivateLink />} />
          <Route path="/:jobId/:companySlug/:jobSlug/welcome/*" element={<CompanyWelcomePageWrapper />} />
          <Route path="/:companySlug/:jobSlug/welcome/*" element={<CompanyWelcomePageWrapper />} />
          <Route path="/:candidateId/welcome/*" element={<CompanyWelcomePageWrapper isPrivateLink />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  </IntercomProvider>
);

export default App;
